<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP SERVICE REQUEST CREATE

type    : view

uses    : main-container
          header-view
          main-card
          simple-dialog-template
          address-validation-map

route   : /serviceRequests/create
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-service-request-create">
    <header-view
      title="New Service Request"
      short_title="New SR"
      :btnOptions="[
        {
          name: 'Submit',
          action: handleSubmit,
          btnColor: 'button-primary',
        },
      ]"
      previous_page="/serviceRequests" />
    <main-container>
      <main-card>
        <v-form
          v-if="client"
          ref="form"
          @submit.prevent="handleSubmit"
          id="serviceRequestForm">
          <v-row>
            <v-col :cols="12" :sm="6">
              <div class="title">New Client Info</div>
              <div class="mt-4 subtitle-1">
                Looking for one of your clients? Go to your
                <router-link to="/clients" class=""
                  >existing Clients.</router-link
                >
              </div>
              <v-text-field
                id="client_name"
                v-model="client.client_name"
                label="New Client Name"
                :rules="validate_client_name" />
              <v-select
                id="client_type"
                v-model="client.client_type"
                :items="possible_client_types"
                label="New Client Type"
                :rules="validate_client_type" />
            </v-col>
            <v-col :cols="12" :sm="6">
              <div class="title">Contact Info</div>
              <v-text-field
                id="contact_name"
                v-model="client.contact_name"
                label="Contact Name"
                :rules="validate_contact_name" />
              <v-text-field
                id="contact_phone"
                v-model="client.contact_phone"
                label="Contact Phone Number"
                :rules="validate_contact_phone" />
              <v-text-field
                id="contact_email"
                v-model="client.contact_email"
                label="Contact Email Address"
                :rules="validate_contact_email"
                :error="emailUniqueError"
                :error-messages="emailUniqueMessage" />
            </v-col>
          </v-row>
          <v-row>
            <!-- job site entry -->
            <v-col :cols="12" :sm="6">
              <div class="title">Job Site Address</div>
              <v-checkbox
                v-model="billingJobSiteSame"
                label="Job Site is same as Billing Address" />

              <!-- job site name -->
              <v-text-field
                id="job_site_name"
                v-model="client.job_site_name"
                label="Job Site Name" />

              <!-- address search and display map -->
              <!-- since this will always be a new jobsite search, addressSearch prop is not used -->
              <address-validation-map
                v-on:jobSiteValidated="updateJobSiteFields"
                :rules="validate_address" />
            </v-col>
            <v-col :cols="12" :sm="6">
              <div class="title">Billing Address</div>
              <v-text-field
                id="address"
                v-model="client.address"
                label="Address"
                :rules="validate_address"
                :disabled="!!billingJobSiteSame" />
              <v-text-field
                id="city"
                v-model="client.city"
                label="City"
                :rules="validate_city"
                :disabled="!!billingJobSiteSame" />
              <v-text-field
                id="state"
                v-model="client.state"
                label="State"
                :rules="validate_state"
                :disabled="!!billingJobSiteSame" />
              <v-text-field
                id="zip_code"
                v-model="client.zip_code"
                label="Postal Code"
                :rules="validate_zip_code"
                :disabled="!!billingJobSiteSame" />
              <v-text-field
                id="country"
                v-model="client.country"
                label="Country"
                :rules="validate_country"
                :disabled="!!billingJobSiteSame" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                id="special_instructions"
                v-model="serviceRequest.special_instructions"
                label="Special Instructions" />
            </v-col>
          </v-row>
        </v-form>
      </main-card>
    </main-container>

    <!-- error dialog -->
    <v-dialog v-model="errorDialog" persistent max-width="500">
      <v-card color="grey lighten-4" min-width="350px" flat :loading="loading">
        <v-card-title class="headline" flat>
          {{ errorHeader }}
        </v-card-title>

        <!-- error message -->
        <v-card-text>
          <div class="subheading">{{ errorMessage }}</div>
        </v-card-text>

        <!-- if quickbooks duplicate name error, prompt customer to update client name -->
        <v-card-text v-if="quickbooksNameError">
          <v-form ref="clientNameUpdateForm">
            <v-text-field
              id="newClientName"
              v-model="newClientName"
              label="New Client Name"
              :rules="validate_new_client_name" />
          </v-form>
        </v-card-text>

        <!-- action buttons -->
        <v-card-actions>
          <v-spacer />

          <!-- if client creation or service request creation errors, display error and stop -->
          <v-btn
            v-if="newClientError || newServiceRequestError"
            color="green darken-1"
            text
            @click="
              () => {
                this.errorDialog = false;
              }
            ">
            Close
          </v-btn>

          <!-- if unknown quickbooks error, continue creating service request -->
          <v-btn
            v-if="quickbooksOtherError"
            color="green darken-1"
            text
            @click="
              () => {
                this.errorDialog = false;
                this.createNewServiceRequest();
              }
            ">
            Close
          </v-btn>

          <!-- if quickbooks duplicate name error, prompt customer to update client name -->
          <v-btn
            v-if="quickbooksNameError"
            color="green darken-1"
            text
            @click="updateClientNameDueToQuickbooks()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import HeaderView from '@/components/header-view';
  import MainCard from '@/components/main-card';
  import AddressValidationMap from '@/components/address-validation-map';

  // mixins
  import email from '@/mixins/email';
  import Forms from '@/mixins/forms.js';
  import configurations from '@/mixins/configurations';

  // services
  import Clients from '@/services/Clients.service.js';
  import Jobs from '@/services/Jobs.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppServiceRequestCreate',
    components: {
      'main-container': MainContainer,
      'header-view': HeaderView,
      'main-card': MainCard,
      'address-validation-map': AddressValidationMap,
    },
    mixins: [email, Forms, configurations],
    data() {
      return {
        billingJobSiteSame: 1,
        possible_client_types: ['Business', 'Residence'],
        errorMessage: '',
        errorDialog: false,
        loader: false,
        loading4: false,
        serviceRequest: null,
        client: null,
        submitted: false,
        emailUniqueError: false,
        emailUniqueMessage: '',
        newClientError: false,
        newServiceRequestError: false,
        quickbooksOtherError: false,
        quickbooksNameError: false,
        newClientName: '',
        oldClientName: '',
        validate_new_client_name: [
          (v) => !!v || 'Client Name is required',
          (v) => v != this.oldClientName || 'Please enter a new client name',
        ],
        errorHeader: 'Error',
        loading: false,
      };
    },
    computed: {},
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 3000);
        this.loader = null;
      },
      billingJobSiteSame() {
        this.updateJobSiteFields();
      },
      'client.address': function () {
        if (this.billingJobSiteSame) {
          this.client.job_site_address = this.client.address;
        }
      },
      'client.city': function () {
        if (this.billingJobSiteSame) {
          this.client.job_site_city = this.client.city;
        }
      },
      'client.state': function () {
        if (this.billingJobSiteSame) {
          this.client.job_site_state = this.client.state;
        }
      },
      'client.zip_code': function () {
        if (this.billingJobSiteSame) {
          this.client.job_site_zip_code = this.client.zip_code;
        }
      },
      'client.country': function () {
        if (this.billingJobSiteSame) {
          this.client.job_site_country = this.client.country;
        }
      },
      'client.contact_email': async function (value) {
        // this will clear out email in use message if
        // the field was cleared.
        // Email required error will stil display
        if (value == null || value == '') {
          this.emailUniqueError = false;
          this.emailUniqueMessage = '';
          return;
        }

        if (value && value.length > 0) {
          // if field has errors OTHER THAN the unique email check error,
          // don't bother running it against database as it doesn't meet validation
          // requirements
          if (
            this.emailUniqueError == false &&
            this.$refs.contact_email &&
            this.$refs.contact_email.hasError
          ) {
            return;
          }

          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // find out if email is already taken
          const matchedClients = await Clients.checkIfClientEmailIsTaken(
            {
              tenant_uuid: this.$auth.userProfile.tenant_uuid,
              contact_email: value,
            },
            accessToken
          );

          // if taken, update input field with custom error
          if (matchedClients) {
            this.emailUniqueError = true;
            this.emailUniqueMessage =
              'There is already a client under this email address';
          } else {
            this.emailUniqueError = false;
            this.emailUniqueMessage = '';
          }
        }
      },
    },
    created() {
      // create new client object
      this.client = Clients.blankClient();

      // create new service request object
      this.serviceRequest = Jobs.blankServiceRequest();

      // set tenant uuid and tenant url for new client
      this.client.tenant_uuid = this.$auth.userProfile.tenant_uuid;
      this.client.tenant_url = this.subdomain;
      this.client.job_site_uuid = Clients.newJobSiteUUID();
      this.serviceRequest.tenant_uuid = this.$auth.userProfile.tenant_uuid;

      // update jobsite fields
      this.updateJobSiteFields();
    },
    methods: {
      updateJobSiteFields: function (validatedAddress) {
        if (validatedAddress) {
          this.client.job_site_address = validatedAddress.address;
          this.client.job_site_city = validatedAddress.city;
          this.client.job_site_state = validatedAddress.state;
          this.client.job_site_zip_code = validatedAddress.zip_code;
          this.client.job_site_country = validatedAddress.country;

          if (this.billingJobSiteSame) {
            this.client.address = this.client.job_site_address;
            this.client.city = this.client.job_site_city;
            this.client.state = this.client.job_site_state;
            this.client.zip_code = this.client.job_site_zip_code;
            this.client.country = this.client.job_site_country;
          }
        }
      },
      async handleSubmit() {
        this.loader = 'loading4';

        // since email unique error is done manually, the check must be done
        // via both the form rules and the emailUniqueError
        if (!this.$refs.form.validate() || this.emailUniqueError) {
          return false;
        }

        let newClientData = {
          uuid: this.client.uuid,
          tenant_uuid: this.client.tenant_uuid,
          number: this.client.number,
          client_name: this.client.client_name,
          contact_name: this.client.contact_name,
          contact_phone: this.client.contact_phone,
          contact_email: this.client.contact_email,
          address: this.client.address,
          city: this.client.city,
          state: this.client.state,
          zip_code: this.client.zip_code,
          country: this.client.country,
          client_type: this.client.client_type,
          client_status: this.client.client_status,
          tenant_url: this.client.tenant_url,
        };

        if (
          this.client.job_site_address &&
          this.client.job_site_city &&
          this.client.job_site_state &&
          this.client.job_site_zip_code &&
          this.client.job_site_country
        ) {
          newClientData['addJobSite'] = true;
          newClientData['job_site_uuid'] = this.client.job_site_uuid;
          newClientData['job_site_name'] = this.client.job_site_name;
          newClientData['job_site_address'] = this.client.job_site_address;
          newClientData['job_site_city'] = this.client.job_site_city;
          newClientData['job_site_state'] = this.client.job_site_state;
          newClientData['job_site_country'] = this.client.job_site_country;
          newClientData['job_site_zip_code'] = this.client.job_site_zip_code;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create new client
        let res = await Clients.createClient(newClientData, accessToken);

        // if client was successfully created
        if (res) {
          // update service request with new client info
          this.serviceRequest.client_uuid = res.uuid;
          this.serviceRequest.client_name = res.client_name;
          this.serviceRequest.requestor_name = res.contact_name;
          this.serviceRequest.requestor_phone = res.contact_phone;
          this.serviceRequest.requestor_email = res.contact_email;
          this.serviceRequest.address = res.address;
          this.serviceRequest.city = res.city;
          this.serviceRequest.state = res.state;
          this.serviceRequest.zip_code = res.zip_code;
          this.serviceRequest.country = res.country;

          // if we enabled quickbooks for this app and the tenant has
          // quickbooks enabled, create quickbooks client
          if (
            this.configuration &&
            this.configuration.quickbooks &&
            this.$auth.tenantProfile.quickbooks_enabled
          ) {
            this.uploadClientToQuickbooks();
          } else {
            this.createNewServiceRequest();
          }
        } else {
          // TODO: better error handling
          this.newClientError = true;
          this.errorHeader = 'Unknown Error';
          this.errorMessage = 'Error creating new client';
          this.errorDialog = true;
        }
      },

      async createNewServiceRequest() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        const sr_created = await Jobs.createServiceRequest(
          this.serviceRequest,
          accessToken
        );

        if (sr_created) {
          this.submitted = true;

          const res_update_sr = await Jobs.updateServiceRequest(
            this.serviceRequest.uuid,
            {
              job_site_uuid: this.client.job_site_uuid,
            },
            accessToken
          );

          /* 
            disabled due to event creation not being functional, 
            which, in turn, breaks new service request submission 

            var url =
              window.location.protocol +
              '//' +
              window.location.hostname +
              '/serviceRequests/view/' +
              this.serviceRequest.uuid;

            this.createEvent({
              link: url,
              users: this.serviceRequest.client_uuid
                ? [this.serviceRequest.client_uuid]
                : undefined,
              notification_name: 'SERVICE-REQUEST-SUBMITTED',
              clientUuid: this.serviceRequest.client_uuid,
            });
            */

          if (res_update_sr) {
            this.$router.push(
              '/serviceRequests/view/' +
                this.serviceRequest.uuid +
                '?tab=serviceRequests'
            );
          } else {
            // TODO: better error handling
            this.newServiceRequestError = true;
            this.errorHeader = 'Unknown Error';
            this.errorMessage =
              'Error updating newly created service request with new job site uuid';
            this.errorDialog = true;
          }
        } else {
          // TODO: better error handling
          this.newServiceRequestError = true;
          this.errorHeader = 'Unknown Error';
          this.errorMessage = 'Error creating service request';
          this.errorDialog = true;
        }
      },
      uploadClientToQuickbooks: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // set qb name (for duplicate name adjustments)
        this.newClientName = this.client.client_name;
        this.oldClientName = this.client.client_name;

        // create new or sync existing quickbooks client
        const resp = await Tenants.createQuickbooksClient(
          this.client,
          accessToken
        );

        // stop spinner on dialog
        this.loading = false;

        // if no errors, continue
        if (resp && resp.quickbooks_id) {
          this.client.quickbooks_id = resp.quickbooks_id;
          this.errorDialog = false;
          this.quickbooksNameError = false;
          this.quickbooksOtherError = false;

          // now that client info is correct, create service request
          this.createNewServiceRequest();

          // handle errors
        } else {
          // if duplicate name error, prompt customer to change client name
          if (resp && resp.changeName) {
            this.quickbooksNameError = true;
            this.errorHeader = 'Client Name in Use';
            this.errorMessage =
              'The provided Client Name already exists in Quickbooks  with a different email. Please enter a different Client Name to avoid potential conflicts';

            // otherwise display whatever error information is available and continue
          } else {
            this.quickbooksOtherError = true;
            this.errorHeader = 'Unknown error';
            this.errorMessage =
              resp.message ||
              'An error occurred while linking this client to quickbooks online - please contact support';
          }

          this.errorDialog = true;
        }
      },
      updateClientNameDueToQuickbooks: async function () {
        // validate name
        if (!this.$refs.clientNameUpdateForm.validate()) {
          return false;
        }

        // set loading spinner
        this.loading = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        this.client.client_name = this.newClientName;

        let res = await Clients.updateClient(
          this.client.uuid,
          this.client,
          accessToken
        );

        if (res) {
          this.uploadClientToQuickbooks();
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
